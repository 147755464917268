import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getAssinaturasCobranca(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/agente/assinatura/cobranca', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

  },
}
