<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          {{ $t('futures.agente.assinaturaCobranca.titleFiltros') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('futures.agente.assinaturaCobranca.tableHeader.email')"
              label-for="palavraChave"
            >
              <b-form-input
                id="palavraChave"
                v-model="palavraChaveFilter"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('futures.agente.assinaturaCobranca.tableHeader.statusPagamento') }}</label>
            <v-select
              v-model="statusPagamentoFilter"
              :options="statusPagamentoOptions"
              :clearable="false"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">acao</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('futures.agente.assinaturaCobranca.pesquisarBtn') }}

            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :busy="loadingOverlay"
        show-empty
        :empty-text="$t('futures.emptyText')"
        :sort-desc.sync="isSortDirDesc"
        striped
        style="min-height: 250px"
      >

        <template #cell(dataInicio)="data">
          {{ formattedDateTimeMoment(data.value) }}
        </template>

        <template #cell(dataFim)="data">
          {{ formattedDateTimeMoment(data.value) }}
        </template>

        <template #cell(dataPagamento)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(statusAssinatura)="data">
          <b-badge :variant="data.value == 'Ativo'? 'light-success': 'light-danger' ">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(statusPagamento)="data">
          <b-badge :variant="getStatusPagamentoStyle(data.value)">
            {{ data.value }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('futures.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BCardBody, BButton, BBadge, BCardHeader, BFormGroup, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateBR } from '@/utils/filter'
import assinanturaCobrancaStoreModule from './assinaturaCobrancaStoreModule'

const AGENTE_ASSINANTURA_COBRANCA_FUT_STORE_MODULE_NAME = 'agente-assinantura-cobranca-fut'

export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  components: {
    BCard,
    BCardHeader,
    BTable,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateBR,
      perPage: 15,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'usuario.nome,asc',
      isSortDirDesc: true,
      dataList: [],
      statusPagamentoFilter: null,
      palavraChaveFilter: null,
      loadingOverlay: false,
    }
  },

  computed: {

    statusPagamentoOptions() {
      return [
        { label: this.$i18n.t('futures.agente.assinaturaCobranca.todos'), value: null },
        { label: this.$i18n.t('futures.agente.assinaturaCobranca.emaberto'), value: '0' },
        { label: this.$i18n.t('futures.agente.assinaturaCobranca.pendentepagamento'), value: '1' },
        { label: this.$i18n.t('futures.agente.assinaturaCobranca.pago'), value: '2' },
        { label: this.$i18n.t('futures.agente.assinaturaCobranca.cancelado'), value: '4' },
      ]
    },

    tableColumns() {
      return [
        { key: 'idAssinatura', label: 'ID' },
        { key: 'nome', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.nome') },
        { key: 'email', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.email') },
        { key: 'planoNome', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.planoNome') },
        { key: 'planoVariacaoNome', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.planoVariacaoNome') },
        { key: 'statusAssinatura', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.statusAssinatura') },
        { key: 'dataInicio', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.dataInicial') },
        { key: 'dataFim', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.dataFinal') },
        { key: 'statusPagamento', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.statusPagamento') },
        { key: 'dataPagamento', label: this.$i18n.t('futures.agente.assinaturaCobranca.tableHeader.dataPagamento') },
      ]
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(AGENTE_ASSINANTURA_COBRANCA_FUT_STORE_MODULE_NAME)) store.registerModule(AGENTE_ASSINANTURA_COBRANCA_FUT_STORE_MODULE_NAME, assinanturaCobrancaStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(AGENTE_ASSINANTURA_COBRANCA_FUT_STORE_MODULE_NAME)) store.unregisterModule(AGENTE_ASSINANTURA_COBRANCA_FUT_STORE_MODULE_NAME)
  },

  methods: {

    formattedDateMoment(originalDate) {
      return moment(originalDate).format('DD/MM/YYYY')
    },

    formattedDateTimeMoment(originalDate) {
      if (originalDate) {
        return moment(originalDate).format('DD/MM/YYYY HH:mm:ss')
      }
      return ''
    },

    buscarFiltro() {
      this.currentPage = 1
      this.fetchList()
    },

    getStatusPagamentoStyle(status) {
      if (status === 'Pago') {
        return 'light-success'
      } if (status === 'Aguardando pagamento') {
        return 'light-warning'
      } if (status === 'Cancelado') {
        return 'light-danger'
      }
      return 'light-primary'
    },

    fetchList() {
      if (!this.statusPagamentoFilter) {
        this.statusPagamentoFilter = this.statusPagamentoOptions.find(option => option.value === '0')
      }

      store
        .dispatch('agente-assinantura-cobranca-fut/getAssinaturasCobranca', {
          size: this.perPage,
          page: this.currentPage - 1,
          sort: this.sortBy,
          sortDesc: this.isSortDirDesc,
          statusPagamento: this.statusPagamentoFilter.value,
          chave: this.palavraChaveFilter,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
